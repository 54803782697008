// import App from '@/main'
import * as ROUTES from "../routes";
import store from "../../store/index";

const routes = [
  {
    path: ROUTES.PORTFOLIO,
    name: "Portfolio",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/portfolio"),
    redirect: { name: "Thrift Flex"},
    children: [
      // {
      //   path: "",
      //   name: "Portfolio",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ "@/views/portfolio/components/PortfolioCards"
      //     ),
      //   meta: {
      //     title: "Project Save",
      //     layout: "private",
      //     requiresAuth: true,
      //   },
      //   beforeEnter: (to, from, next) => {
      //     const bankAdded = localStorage.getItem("bank");
      //     if (bankAdded == "false") {
      //       store.dispatch("snackbar/openSnackbar", {
      //         text: "Please add your bank details",
      //       });
      //       next("/profile/bank-account");
      //     } else {
      //       next();
      //     }
      //   },
      // },

      // THRIFTFLEX ROUTES
      {
        path: ROUTES.THRIFTFLEX,
        name: "Thrift Flex",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/thriftFlex"),
        meta: {
          title: "AJO (Thrift)",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.THRIFTFLEX_INITIATE,
        name: "Tf-Contribution",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/thriftFlex/components/InitiateSavings"
          ),
        meta: {
          title: "Thrift Flex",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.THRIFTFLEX_TOPUP,
        name: "Tf-Topup",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/WalletTopup"
          ),
        meta: {
          title: "Thrift Flex",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      // PROJECTSAVE ROUTES
      {
        path: ROUTES.PROJECTSAVE,
        name: "Project Save",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/projectSave"),
        meta: {
          title: "Project Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.PROJECTSAVE_INITIATE,
        name: "Prs-Contribution",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/projectSave/components/InitiateSavings"
          ),
        meta: {
          title: "Project Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.PROJECTSAVE_TRANSFER,
        name: "Prs-Transfer-Fund",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/projectSave/components/TransferFund"
          ),
        meta: {
          title: "Withdrawal",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.PROJECTSAVE_WITHDRAWAL,
        name: "Prs-Withdrawal",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/projectSave/components/Withdrawal"
          ),
        meta: {
          title: "Withdrawal",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.PROJECTSAVE_TOPUP,
        name: "Prs-Topup",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/WalletTopup"
          ),
        meta: {
          title: "Project Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      // POWERSAVE ROUTES
      {
        path: ROUTES.POWERSAVE,
        name: "Power Save",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/powerSave"),
        meta: {
          title: "Power Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.POWERSAVE_WITHDRAWAL,
        name: "Pws-Withdrawal",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/powerSave/components/Withdrawal"
          ),
        meta: {
          title: "Power Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.POWERSAVE_INITIATE,
        name: "Pws-Contribution",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/powerSave/components/InitiateSavings"
          ),
        meta: {
          title: "Power Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.POWERSAVE_TOPUP,
        name: "Pws-Topup",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/WalletTopup"
          ),
        meta: {
          title: "Power Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      // SUPERSAVE ROUTES
      {
        path: ROUTES.SUPERSAVE,
        name: "Super Save",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/superSave"),
        meta: {
          title: "Super Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.SUPERSAVE_WITHDRAWAL,
        name: "Sps-Withdrawal",
        component: () => import(
          /* webpackChunkName: "about" */ "@/views/superSave/components/Withdrawal"
        ),
        meta: {
          title: "Super Save",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      // INTEREST ROUTES
      {
        path: ROUTES.INTEREST,
        name: "Interest",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/interest"),
        children: [],
        meta: {
          title: "Interest",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.INTEREST_WITHDRAWAL,
        name: "Interest Withdrawal",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/interest/InterestWithdrawal"
          ),
        children: [],
        meta: {
          title: "Interest",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      // LOAN ROUTES
      {
        path: ROUTES.EMERGENCY_FUNDS,
        name: "Emergency Funds",
        component: () => import(/* webpackChunkName: "about" */ "@/views/loan"),
        meta: {
          title: "Emergency Funds",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.EMERGENCY_FUNDS_REQUEST,
        name: "Emergency Funds Request",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/loan/components/LoanRequest"
          ),
        meta: {
          title: "Emergency Funds",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
      {
        path: ROUTES.EMERGENCY_FUNDS_PAYMENT,
        name: "Emergency Funds Payment",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/loan/components/MakePayment"
          ),
        meta: {
          title: "Emergency Funds",
          layout: "private",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const bankAdded = localStorage.getItem("bank");
          if (bankAdded == "false") {
            store.dispatch("snackbar/openSnackbar", {
              text: "Please add your bank details",
            });
            next("/profile/bank-account");
          } else {
            next();
          }
        },
      },
    ],
    meta: {
      title: "Portfolio",
      layout: "private",
      requiresAuth: true,
    },
  },
];

export default routes;
