export const ONBOARDING = "/";
export const DASHBOARD = "/dashboard";
export const PORTFOLIO = "/portfolio";
export const REWARDS = "/rewards";
export const REWARDS_WITHDRAWAL = "/rewards/withdrawal";

export const PROFILE = "/profile";
export const EDITPROFILE = "edit-profile";
export const BANKACCOUNT = "bank-account";
export const CHANGEPIN = "change-pin";
export const ABOUT = "about";
export const KYC = "kyc";
export const EMERGENCY_FUNDS = "emergency-funds";
export const EMERGENCY_FUNDS_REQUEST = "emergency-funds/request";
export const EMERGENCY_FUNDS_PAYMENT = "emergency-funds/payment";
export const PROJECTSAVE = "project-save";
export const PROJECTSAVE_INITIATE = "project-save/prs-contribution";
export const PROJECTSAVE_TRANSFER = "project-save/prs-transfer-fund"; 
export const PROJECTSAVE_WITHDRAWAL = "project-save/prs-withdrawal"; 
export const PROJECTSAVE_TOPUP = "project-save/topup"; 
export const POWERSAVE = "power-save";
export const POWERSAVE_INITIATE = "power-save/pws-contribution";
export const POWERSAVE_WITHDRAWAL = "power-save/pws-withdrawal";
export const POWERSAVE_TOPUP = "power-save/topup";
export const SUPERSAVE = "super-save";
export const SUPERSAVE_WITHDRAWAL = "super-save/sps-withdrawal";
export const THRIFTFLEX = "thrift-flex";
export const THRIFTFLEX_INITIATE = "thrift-flex/tf-contribution";
export const THRIFTFLEX_TOPUP = "thrift-flex/topup"; 
export const LOGIN = "/login";
export const FEDDBACK = "/feed-back";
export const CHANGE_NUMBER = "/change-number";
export const REGISTER = "/register";
export const RESET = "/reset-pin";
export const FORGOT = "/forgot-pin";
export const VERIFY = "/verify-account";
export const INTEREST = 'interest'
export const INTEREST_WITHDRAWAL = 'interest/withdrawal'

